// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import _ from 'lodash'
import Axios from 'axios'
import * as forgeRock from '@forgerock/javascript-sdk'
import jwtDecode from 'jwt-decode'

export const cognitoClientId = _.get(window, 'config.userPoolClientId')
export const cognitoDomain = _.get(window, 'config.userPoolDomain')

/**
 * Axios instance pre-configured instance
 */
export const api = Axios.create({
  baseURL: window.env.API_URL,
  responseType: 'json',
  validateStatus: (status) => status >= 200 && status <= 403,
})

/**
 * Inject ForgeRock access token as Bearer token in request
 */
api.interceptors.request.use(
  async ({ headers, ...requestConfig }) => {
    const tokens = (await forgeRock.TokenStorage.get())

    const authHeader = tokens?.accessToken
      ? { Authorization: `Bearer ${tokens.accessToken}` }
      : {}

    return {
      ...requestConfig,
      headers: {
        ...headers,
        ...authHeader,
      },
    }
  }
)

api.interceptors.response.use(async (response) => {
  if (response.status === 401) {
    const tokens = await forgeRock.TokenStorage.get()

    if (
      tokens?.accessToken === undefined ||
      (tokens?.accessToken !== undefined &&
        jwtDecode < { exp: number } > (tokens.accessToken)['exp'] <
        new Date().getTime() / 1000)
    ) {
      await forgeRock.TokenManager.getTokens({
        forceRenew: true,
      })
      return api.request(response.config)
    }
  }

  return response
})

export function apiGatewayClient () {
  return Promise.resolve(api)
}

export function apiGatewayClientWithCredentials () {
  return Promise.resolve(api)
}
