import React, { Component } from 'react'
import {BrowserRouter as Router, Routes, Navigate, Route, BrowserRouter, Outlet} from 'react-router-dom'

import { ApiManagement, SideNav } from './'
import { AdminRoute } from './../../'
import PageWithSidebar from 'components/PageWithSidebar'

import RegisteredAccounts from 'pages/Admin/Accounts/RegisteredAccounts'
import AdminAccounts from 'pages/Admin/Accounts/AdminAccounts'
import PendingInvites from 'pages/Admin/Accounts/PendingInvites'
import {isAdmin} from "../../services/self";
// import PendingRequests from 'pages/Admin/Accounts/PendingRequests'

function NavigateToApiManagement () {
  return <Navigate to='/admin/apis' />
}
 const MainUserRoute = () => {
  const auth = isAdmin(); // determine if authorized, from context or however you're doing it
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  console.log("inside index",auth);
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export class Admin extends Component{
  render () {
    return (
        <PageWithSidebar sidebarContent={<SideNav />}>
      <Routes>

          <Route  path="/" element={<MainUserRoute />} >
            <Route  path='/' element={<ApiManagement />} />
          </Route>
          <Route path="/admin/accounts" element={<MainUserRoute />} >
            <Route exact path='/admin/accounts' element={<RegisteredAccounts />} />
          </Route>
          {/*<AdminRoute exact path='/admin/accounts/admins' component={AdminAccounts} />*/}
          {/*<AdminRoute exact path='/admin/accounts/invites' component={PendingInvites} />*/}
          {/* <AdminRoute exact path='/admin/accounts/requests' component={PendingRequests} /> */}
      </Routes>
        </PageWithSidebar>

    )
  }
}
