import { TokenManager, UserManager } from "@forgerock/javascript-sdk";
import React, { useContext, useEffect } from "react";
import { useNavigate as useHistory } from "react-router-dom";
import { UserContext } from "../../index";
import { store } from "../../services/state"

const redirectToFRJourney = async () => {
  console.log("Authorising...");
  // This will redirect the user to ForgeRock. It will redirect them back after they've
  // logged in/registered.
  await TokenManager.getTokens({
    forceRenew: false,
    login: "redirect",
  });
};

const userFromAuthCode = async (code, state, setUserState, pageHistory) => {
  // Exchange the authorisation code with an access token
  const token = await TokenManager.getTokens({
    forceRenew: false,
    query: {
      code: code,
      state: state,
    },
  });
  store.idToken = token.accessToken;
  // Get the user from ForgeRock
  const user = await UserManager.getCurrentUser();

  // Set the user in context
  if (setUserState !== undefined) {
    setUserState({
      user: {
        ...user,
        // Add a flag to indicate whether the user is anonymous
        // note: currently unused since we assume there is no anonymous access
        anonymousUser: user?.email === "anonymous@example.com" || undefined,
      },
    });
  }
  pageHistory("/apis");
};

const ForgeRockAuthentication = () => {
  const { userState, setUserState } = useContext(UserContext);
  const pageHistory = useHistory();
  // Redirect to ForgeRock for authentication
  useEffect(() => {
    // If the user was redirected here in the authorisation code flow, the
    // authorisation code and the opaque state value will be set as query
    // params.
    const queryParams = new URLSearchParams(document.location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");
    if (code && state) {
      // Exchange the authorisation code for an access token and store it in
      // session storage.
      userFromAuthCode(code, state, setUserState, pageHistory);
    } else if (userState !== undefined) {
      // The user is already authenticated, so redirect back home

      pageHistory("/apis");
    } else {
      redirectToFRJourney();
    }
  }, []);
  return <div className="text-center w-100">Signing In...</div>;
};

export default ForgeRockAuthentication;
