// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

// TODO modify these to use C1 instead of Cognito

// services
import * as jwtDecode from 'jwt-decode'

import { store } from 'services/state'

export function isAuthenticated () {
  return store.idToken;
}

function getPreferredRole () {
  return jwtDecode(store.idToken)['groups'] || ''
}

export function isRegistered () {
  if (!store.idToken) {
    return false
  }

  const role = getPreferredRole()
  return (
    role.includes('dev-portal-admin') ||
    role.includes('-CognitoRegisteredRole-')
  )
}

export function isAdmin () {
  return store.idToken && getPreferredRole().includes('dev-portal-admin')
}

export function isAllowed() {
  return store.idToken && getPreferredRole().includes('dev-portal')
}

let logoutTimer

function getRemainingSessionTime (idToken) {
  return jwtDecode(idToken).exp * 1000 - Date.now()
}

/**
 * On page load, look for an active cookie. If it exists and isn't expired, great, use it. Otherwise, clear everything and make sure we're not logged in.
 */
export function init () {
  // attempt to refresh credentials from active session

  let idToken
  let diff = 0

  try {
    idToken = JSON.parse(window.sessionStorage.getItem("forgerock-sdk-AWS-DEV-Portal"))
    if (idToken) diff = getRemainingSessionTime(idToken.accessToken)
  } catch (error) {
    console.error(error)
  }
  if (diff > 0) {
    store.idToken = idToken.accessToken
    logoutTimer = setTimeout(logout, diff)
    setCredentials()
  } else {
    logout()
  }
}

/**
 * Gets triggered by the callback from the cognito user pool. Pretty much all it does is grab and store the idToken.
 */


export const getLoginRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=login`
export const getLogoutRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=logout`

function setCredentials () {
  console.log(store.idToken);
  const preferredRole = jwtDecode(store.idToken); //['cognito:preferred_role']
  console.log("Preferred role",preferredRole);
  // const params = {
  //   IdentityPoolId: cognitoIdentityPoolId,
  //   Logins: {
  //     [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]: store.idToken
  //   }
  // }
  //
  // if (preferredRole) params.RoleArn = preferredRole
  //
  // AWS.config.credentials = new AWS.CognitoIdentityCredentials(params)
  //
  // return new Promise((resolve, reject) => {
  //   AWS.config.credentials.refresh(error => {
  //     if (error) {
  //       console.error(error)
  //       return reject(error)
  //     }
  //
  //     store.user = { email: jwtDecode(store.idToken).email }
  //     updateAllUserData()
  //
  //     return apiGatewayClient()
  //       .then(apiGatewayClient => apiGatewayClient.post('/signin', {}, {}))
  //   })
  // })
}

/**
 * Callback for the Cognito User Pool's logout just to make sure we clean up everything.
 */
export function logout () {
  clearTimeout(logoutTimer)
  logoutTimer = undefined
  if (store.idToken) {
    store.resetUserData()
    window.localStorage.clear()
    window.sessionStorage.clear()
    window.location = `/`

  }
}
