// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, {Component, useContext, useState} from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes
} from "react-router-dom";

// content-fragments
import { loadFragments } from "services/get-fragments";

// semantic-ui
import "semantic-ui-css/semantic.css";

// pages
import { Admin } from "pages/Admin";
import Apis from "pages/Apis";
import Dashboard from "pages/Dashboard";
import GettingStarted from "pages/GettingStarted";
import Home from "pages/GettingStarted";

// components
import AlertPopup from "components/AlertPopup";
import GlobalModal from "components/Modal";
import NavBar from "components/NavBar";
import ApiSearch from "./components/ApiSearch";
import Feedback from "./components/Feedback";

import { Config } from "@forgerock/javascript-sdk";
import {init, isAdmin, isAllowed, isRegistered, logout} from "services/self";
import ForgeRockAuthentication from "./components/auth/ForgeRockAuthentication";
import "./index.css";
//import {Route53RecoveryCluster} from "aws-sdk";

loadFragments();

Config.set({
  clientId: window.env.CLIENT_ID,
  redirectUri: window.env.REDIRECT_URI,
  scope: "openid",
  serverConfig: {
    baseUrl: window.env.FORGEROCK_URL,
    timeout: 30000,
  },
  realmPath: window.env.REALM,
  support: "modern",
  tokenStore: "sessionStorage",
  login: "redirect",
  middleware: [
    (req, action, next) => {
      // Append the `acr_values` query param to authorisation requests to tell
      // ForgeRock which journey to use.
      if (action.type === "AUTHORIZE") {
        console.log(
          `Middleware adding query params. acr_values=${window.env.JOURNEY_CUSTOMER_LOGIN}`
        );
        req.url.searchParams.append(
          "acr_values",
          window.env.JOURNEY_CUSTOMER_LOGIN
        );
      }

      next();
    },
  ],
});

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = false;
 const PrivateRoute = () => {
  const auth = isAdmin(); // determine if authorized, from context or however you're doing it
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

const PrivateRoute1= () => {
  const isRegistered = isAllowed();
  return (isRegistered || isAdmin()) ? <Outlet /> : <Navigate to="/login" />;
}


// TODO using the ForgeRock SDK assumes users are already registered, so this
// route should be removed
export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isRegistered() ? <Component {...props} /> : <Navigate to="/" />
    }
  />
);

export const UserContext = React.createContext({
  setUserState: undefined,
  userState: undefined,
});

export const SecureRoute = ({ path, children }) => {
  const { userState } = useContext(UserContext);

  return (
    <Route path={path}>
      {userState === undefined ? (
        // No anonymous access is allowed, so redirect to login
        <ForgeRockAuthentication />
      ) : (
        children
      )}
    </Route>
  );
};
export const AdminRoute = ({ component: Component, ...rest }) => (

  <Route
    {...rest}
    render={(props) =>
      isAdmin() ? <Component {...props} /> : <Navigate to="/" />
    }

  />
);

// To shut up a dev warning
const HomeWrap = (props) => <Home {...props} />;

const App = () => {
  init();

  // We are using an S3 redirect rule to prefix the url path with #!
  // This then converts it back to a URL path for React routing
  if (window.location.hash && window.location.hash[1] === "!") {
    const hashRoute = window.location.hash.substring(2);
    window.history.pushState({}, "home page", hashRoute);
  }

  const [userState, setUserState] = useState();

  return (
    <BrowserRouter>
      <>
        <NavBar />
        <GlobalModal />
        <UserContext.Provider value={{ userState, setUserState }}>
          {userState !== undefined && userState?.user.anonymousUser && (
            <button
              onClick={logout}
              className="btn btn-primary d-inline btn-sm ml-auto"
            >
              Logout
            </button>
          )}
          <Routes>
            <Route exact path="/" element={<GettingStarted />} />
            <Route path="/getting-started" element={<GettingStarted />} />
            <Route path="/dashboard" element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route path="/admin/apis" element= {<PrivateRoute />}>
              <Route path="/admin/apis" element={<Admin />} />
            </Route>
            <Route path="/apis" element={<PrivateRoute1 />} >
            <Route exact path="/apis" element={<Apis />} />
            </Route>
            <Route exact path="/apis/search" element={<ApiSearch />} />
            <Route exact path="/apis/:apiId" element={<Apis />} />
            <Route path="/apis/:apiId/:stage" element={<Apis />} />

            {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Route path="/login" element={<ForgeRockAuthentication />}></Route>
            <Route element={<h2>Page not found</h2>} />
          </Routes>
        </UserContext.Provider>
        {feedbackEnabled && <Feedback />}
        <AlertPopup />
      </>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
