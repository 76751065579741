// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Image, Menu } from 'semantic-ui-react'

import { isAdmin, isAuthenticated, isRegistered, logout, isAllowed } from 'services/self'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'

export const NavBar = observer(
  class NavBar extends React.Component {
    render () {
      const email = store.user && store.user.email
      return <Menu inverted borderless attached style={{ flex: '0 0 auto' }} stackable>
        <MenuLink to="/getting-started">
          <Image size="mini" src="/custom-content/nav-logo.png" style={{ paddingRight: '10px' }}/>
          {fragments.Home.title}
        </MenuLink>


          <MenuLink to="/apis">{fragments.APIs.title}</MenuLink>

        <Menu.Menu position="right">
          {/* // TODO replace Cognito integrations to use the C1 user */}
          {isAuthenticated() ? <>
            {isAdmin() && <MenuLink to="/admin/apis">Admin Panel</MenuLink>}
            {/*{isAdmin() && <MenuLink to="/dashboard">My Dashboard</MenuLink>}*/}
            <MenuLink onClick={logout}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                {email && <span style={{ marginBottom: '.5rem' }}>
                  {email}
                </span>}
                <span>
                  Sign out
                </span>
              </div>
            </MenuLink>
          </> : <>
            <MenuLink to={'/login'}>Sign In</MenuLink>
            {/*<MenuLink to={'/signup'}>Register</MenuLink>*/}
          </>}
        </Menu.Menu>
      </Menu>
    }
  }
)

export default NavBar
